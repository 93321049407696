<template>
  <v-dialog
    v-model="isOpened"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-container>
        <v-form @submit.prevent="onSubmit">
          <v-card-text>
            <Datepicker
              title="Дата"
              v-model="modalExpenses.datetime"
              :clearable="true"
            />

            <v-select
              label="Регион"
              v-model="modalExpenses.region_id"
              :items="regions"
              clearable
              item-value="id"
              item-text="name"
            />

            <v-select
              label="Тип"
              required
              item-value="id"
              item-text="name"
              :items="types"
              v-model="modalExpenses.income_expenses_type_id"
              @input="resetErrors"
              :error-messages="errors.income_expenses_type_id"
            />

            <!-- Для ГСМ -->
            <div
              v-if="modalExpenses.income_expenses_type_id === 42"
            >
              <v-select
                label="Исполнитель"
                required
                item-value="id"
                item-text="name"
                :items="users"
                v-model="user_id"
              />

              <v-select
                label="Дата поездки"
                required
                item-value="id"
                item-text="date"
                :items="mileages"
                v-model="modalExpenses.mileage_id"
              />

              <div v-if="selectedMileage">
                <p
                  v-if="selectedMileage.finish && selectedMileage.start"
                >
                  Пробег: {{ selectedMileage.finish - selectedMileage.start }}км
                </p>

                <v-row>
                  <v-col cols="6">
                    <v-img
                      v-if="selectedMileage.start_image"
                      :src="selectedMileage.start_image.url"
                      max-width="100%"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-img
                      v-if="selectedMileage.finish_image"
                      :src="selectedMileage.finish_image.url"
                      max-width="100%"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>

            <!-- Для выплаты зарплаты -->
            <div
              v-if="modalExpenses.income_expenses_type_id === 50"
            >
              <v-select
                label="Исполнитель"
                required
                item-value="id"
                item-text="name"
                :items="users"
                v-model="paymentOpts.user_id"
                clearable
              />

              <Datepicker
                title="Дата окончания периода"
                v-model="paymentOpts.date_to"
                :clearable="true"
              />
            </div>

            <v-text-field
              label="Сумма"
              required
              type="number"
              v-model="modalExpenses.amount"
              @input="resetErrors"
              :error-messages="errors.amount"
            />

            <v-textarea
              label="Комментарий"
              required
              v-model="modalExpenses.comment"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="blue darken-1"
              text
              @click="onCancelClick"
            >
              Отмена
            </v-btn>

            <v-btn
              color="blue darken-1"
              outlined
              type="submit"
              :disabled="submitDisabled"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { IncomeExpensesService, MileageService } from '@/common/api.service'
import Datepicker from '@/components/Datepicker'

export default {
  props: {
    expenses: {
      default: null
    },
    types: {
      default: () => []
    },
    regions: {
      default: () => []
    },
    users: {
      default: () => []
    }
  },

  components: {
    Datepicker
  },

  data() {
    return {
      modalExpenses: {
        id: null,
        income_expenses_type_id: null,
        mileage_id: null,
        amount: null,
        comment: null,
        region_id: null,
        datetime: null
      },
      // Опции для подсчёта зарплаты
      paymentOpts: {
        date_to: null,
        income_expenses_type_id: 47,
        user_id: null
      },
      isOpened: false,
      valid: true,
      errors: {},
      user_id: null,
      mileages: []
    }
  },

  methods: {
    onSubmit() {
      if (this.expenses.id ) {
        if (this.modalExpenses.income_expenses_type_id === 50) {
          this.modalExpenses.user_id = this.paymentOpts.user_id
          this.modalExpenses.date_to = this.paymentOpts.date_to ?? undefined
        }

        IncomeExpensesService.update(this.modalExpenses).then(() => {
          this.clearForm()
          this.$emit('close', true)
        })
      } else {
        if (this.modalExpenses.income_expenses_type_id === 50) {
          this.modalExpenses.user_id = this.paymentOpts.user_id
          this.modalExpenses.date_to = this.paymentOpts.date_to ?? undefined
        }

        IncomeExpensesService.store(this.modalExpenses)
          .then(() => {
            this.clearForm()
            this.$emit('close', true)
          })
          .catch(error => {
            if (error.response && 422 === error.response.status) {
              this.errors = error.response.data.errors
            }
          })
      }
    },

    onCancelClick() {
      this.clearForm()
      this.$emit('close', false)
    },

    clearForm () {
      this.paymentOpts.date_to = null
      this.paymentOpts.user_id = null
    },

    resetErrors () {
      this.errors = {}
    },

    fetchIncomeExpenses () {
      IncomeExpensesService
        .amountIncomeExpenses(this.paymentOpts)
        .then(({ data }) => {
          this.modalExpenses.amount = Math.abs(data.amount)
        })
    }
  },

  computed: {
    selectedMileage () {
      if (!this.modalExpenses.mileage_id) {
        return null
      }

      return this.mileages.find((mileage) => mileage.id === this.modalExpenses.mileage_id)
    },

    submitDisabled () {
      if (!this.modalExpenses.region_id || !this.modalExpenses.amount || this.modalExpenses.amount < 0) {
        return true
      }

      // Для выплаты ЗП обязателен юзер
      if (this.modalExpenses.income_expenses_type_id === 50) {
        return !this.paymentOpts.user_id
      }

      return false
    }
  },

  watch: {
    expenses (value) {
      if (value) {
        this.isOpened = true
        for (const [key] of Object.entries(this.modalExpenses)) {
          this.modalExpenses[key] = this.expenses[key]
        }
      } else {
        this.isOpened = false
      }
    },

    user_id: {
      handler (value) {
        if (value) {
          MileageService
            .get(false, value)
            .then((response) => {
              this.mileages = response.data
            })
        }
      }
    },

    paymentOpts: {
      handler () {
        if (!this.paymentOpts.user_id) {
          this.modalExpenses.amount = null
          return
        }

        this.fetchIncomeExpenses()
      },
      deep: true
    }
  }
}
</script>
